/* fonts */
@font-face {
  font-family: "Monoton";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monoton/v10/5h1aiZUrOngCibe4TkHLQka4BU4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCIPrcVIT9d0c8.woff)
    format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v19/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
}

/* Global */
html {
  height: 100%;
}
body {
  background: linear-gradient(120deg, #1db954, #191414);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#root {
  width: 100%;
}
a {
  cursor: pointer;
}
button {
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 100;
  font-family: "Raleway";
  color: white;
  background-color: #1db954;
  height: 2.5rem;
  border-style: hidden;
  align-self: center;
  cursor: pointer;
}

/* nav bar */
.navbar {
  background-color: #191414;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  display: flex;
  justify-content: space-around;
}
.nav-button {
  width: 15%;
  height: 1.5rem;
  color: #191414;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 100;
  font-family: "Raleway";
  background-color: #1db954;
  border-style: hidden;
  align-self: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
}
.nav-button:hover {
  background-color: darkgrey;
  color: #191414;
}

/* Title Bar */
header {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding-top: 3rem;
}
#logo {
  color: black;
}
h1 {
  font-family: "Raleway";
  color: #191414;
  font-size: 3.5rem;
  margin: 0;
  text-align: center;
}
span {
  color: darkgrey;
}
h2 {
  font-family: "Raleway";
}
.white {
  color: gray;
}
ul {
  padding-left: 0;
  text-align: center;
}
li {
  font-family: "Raleway";
  font-weight: 600;
  font-size: 1rem;
  list-style-type: none;
  padding-bottom: 1rem;
}
.mobile-title {
  font-family: "Raleway";
  color: #191414;
  font-size: 3.5rem;
  margin: 0;
  text-align: center;
  display: none;
}

@media only screen and (max-width: 770px) {
  h1 {
    display: none;
  }
  .mobile-title {
    display: inline;
  }
}

/* main content */
.main {
  display: grid;
  width: 95%;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(19, 1fr);
  justify-content: center;
  margin: 0 auto;
}
section > img {
  width: 100%;
}
.fas {
  width: 100%;
}
section > button {
  width: 100%;
}
.fas {
  font-size: 3rem;
}
#marvin {
  grid-area: 1 / 1 / span 5 / span 5;
  z-index: 9;
}
#blondie {
  grid-area: 1 / 1 / span 5 / span 5;
  z-index: 9;
  display: none;
}
#miles {
  grid-area: 1 / 15 / span 5 / span 5;
  z-index: 9;
}
#floyd {
  grid-area: 2 / 5 / span 3 / span 3;
  z-index: 8;
}
#beck {
  grid-area: 2 / 13 / span 3 / span 3;
  z-index: 9;
}
#mobileImage {
  width: 85%;
  margin-bottom: 2rem;
  display: none;
}

/* controls */
.icon__track-back {
  grid-area: 3 / 8 / span 1 / span 1;
  color: white;
  justify-self: right;
  font-size: 3rem;
}
.icon__question {
  grid-area: 3 / 10 / span 1 / span 1;
  color: white;
  justify-self: center;
  font-size: 3rem;
}
.icon__track-forward {
  grid-area: 3 / 12 / span 1 / span 1;
  color: white;
  justify-self: left;
  font-size: 3rem;
}

/* play button */
.play {
  grid-area: 6 / 8 / span 1 / span 5;
  text-align: center;
}
.play-button {
  background-color: #191414;
  color: white;
  border-radius: 30px;
  font-family: "Raleway";
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 100;
  text-align: center;
  padding: 0 25% 0 25%;
}
.play-button:hover {
  background-color: darkgrey;
  color: #191414;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .nav-button {
    width: 20%;
    height: 1.5rem;
  }
  header {
    margin-bottom: 0.75rem;
  }
  .main {
    display: grid;
    width: 90%;
    grid-template-rows: repeat(8, 14vw);
    grid-template-columns: repeat(5, 14vw);
    justify-content: center;
  }
  #mobile-image {
    display: block;
    margin-bottom: 0.75rem;
  }
  #miles,
  #beck {
    display: none;
  }
  #marvin {
    grid-area: 2 / 1 / span 5 / span 5;
    z-index: 9;
  }
  #floyd {
    grid-area: 1 / 2 / span 3 / span 3;
    z-index: 1;
  }
  #icon__track-back {
    grid-area: 8 / 2 / span 1 / span 1;
    align-self: end;
  }
  #icon__question {
    grid-area: 8 / 3 / span 1 / span 1;
    align-self: end;
  }
  #icon__track-forward {
    grid-area: 8 / 4 / span 1 / span 1;
    align-self: end;
  }
  #fas {
    font-size: 2rem;
  }
  #play {
    grid-area: 9 / 2 / span 1 / span 3;
    align-self: end;
    margin-top: 4vw;
  }
}

/* Play Screen */
.play-screen {
  display: block;
}
.artist-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 40;
}
label {
  font-family: "Raleway";
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.start-button {
  margin-top: 2rem;
  height: 2rem;
  width: 7rem;
  background-color: #191414;
  color: #ffffff;
}
.start-button:hover {
  background-color: darkgray;
  color: #191414;
}
.artist {
  width: 20%;
  text-align: center;
}
.play-grid {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.image-display {
  width: 100%;
  height: auto;
  box-shadow: 5px 5px 5px #191414;
}
form {
  display: none;
  text-align: center;
}
.display {
  display: block;
}
.display-none {
  display: none;
}
.rel-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  width: 30%;
  border: 2px solid gray;
}
.rel-artist-list-render {
  width: 30%;
}

/* Rules and about Screens */
.info-text {
  background-color: #191414;
  color: #ffffff;
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.text {
  padding: 1.75rem;
  font-size: 1.5rem;
  font-family: "Raleway";
  font-weight: 100;
  text-align: center;
}
.display {
  display: block;
}
.display-none {
  display: none;
}

@media only screen and (max-width: 480px) {
  #text {
    padding: 1.5rem;
    font-size: 1rem;
    font-family: "Raleway";
    font-weight: 100;
    text-align: center;
  }
  .nav-button {
    font-size: 1rem;
    line-height: 1.5;
  }
  .play-button {
    padding: 0 10%;
  }
  .play-grid {
    margin-top: 5%;
    justify-content: space-evenly;
  }
  .artist {
    width: 30%;
  }
}
